import React from "react";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Title from "../../components-v2/Base/Title";
import { quotes } from "../../data/whitepapers/restaurants-hub";
import FeatureImage from "../../components/ImageQuerys/LargeFeaturesImages";
import useStartMotion from "../../hooks/use-start-motion";

const slideBottomVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const slideLeftVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const slideRightVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const Quotes = () => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      className="max-w-1440 w-full px-4 md:px-10 xl:px-40 mt-20 lg:mt-40"
      ref={ref}
    >
      <Title level={2} withBlueSymbol className="text-center">
        What restaurateurs are saying
      </Title>
      {quotes.map((quote, idx) => (
        <div
          key={quote.name}
          className={`
            flex flex-col ${idx % 2 > 0 ? "lg:flex-row-reverse" : "lg:flex-row"}
            items-center
            lg:gap-16 xl:gap-32
            ${idx > 0 ? "mt-16 lg:mt-32" : "mt-16"}
          `}
        >
          <motion.div
            className="max-w-[500px] h-auto w-full"
            animate={controls}
            initial={isMobileOnly ? "visible" : "hidden"}
            variants={slideLeftVariants}
            transition={{ delay: idx * 0.5, duration: 0.8 }}
          >
            <FeatureImage imageName={quote.imageName} />
          </motion.div>

          <motion.div
            className="lg:max-w-[450px] mt-10 lg:mt-0"
            animate={controls}
            initial={isMobileOnly ? "visible" : "hidden"}
            variants={slideRightVariants}
            transition={{ delay: (idx + 1) * 0.7, duration: 0.8 }}
          >
            <div className="text-h3 font-bold">
              {quote.name}
              <span className="text-primary">.</span>
            </div>

            <div className="text-black-200 text-p">{quote.title}</div>

            <p className="text-black-200 text-p mt-6">{quote.quote}</p>
          </motion.div>
        </div>
      ))}
    </section>
  );
};

export default Quotes;
